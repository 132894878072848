import React, { useState } from 'react';
import { Mail, Youtube, Instagram, Facebook, Twitter, Sun, Moon } from 'lucide-react';

const ThemeToggle = ({ isDark, toggle }) => (
  <button 
    onClick={toggle}
    className="flex items-center gap-2 px-4 py-2 rounded-full bg-orange-700 hover:bg-orange-600 transition-all"
  >
    {isDark ? (
      <Sun size={20} className="text-white" />
    ) : (
      <Moon size={20} className="text-white" />
    )}
  </button>
);

const DiamondPattern = ({ isDark }) => (
  <div className={`absolute inset-0 opacity-20 ${isDark ? 'bg-black' : 'bg-white'}`} style={{ 
    backgroundImage: `
      repeating-linear-gradient(45deg, transparent 0, transparent 10px, ${isDark ? '#808080' : '#000000'} 10px, ${isDark ? '#808080' : '#000000'} 11px),
      repeating-linear-gradient(135deg, transparent 0, transparent 10px, ${isDark ? '#808080' : '#000000'} 10px, ${isDark ? '#808080' : '#000000'} 11px)
    `,
    backgroundSize: '30px 30px'
  }} />
);

const SocialLink = ({ href, icon: Icon, label, isText }) => (
  <a 
    href={href} 
    className="hover:text-orange-500 p-2 hover:border hover:border-orange-500 rounded transition-all"
    target="_blank" 
    rel="noopener noreferrer"
  >
    {isText ? (
      <span className="w-6 h-6 inline-flex items-center justify-center font-bold">
        {label}
      </span>
    ) : (
      <Icon size={24} />
    )}
  </a>
);

const socialLinks = [
  { icon: Mail, href: 'mailto:collectorscanyon@gmail.com', label: 'Mail' },
  { icon: Youtube, href: 'https://www.youtube.com/channel/UCtrPrXu3wz0KK4lV30485Cw', label: 'Youtube' },
  { icon: Instagram, href: 'https://www.instagram.com/collectcanyon/', label: 'Instagram' },
  { label: 'W', href: 'https://warpcast.com/collectorscanyon', isText: true },
  { icon: Facebook, href: 'https://www.facebook.com/111077472023480', label: 'Facebook' },
  { icon: Twitter, href: 'https://www.twitter.com/CollectCanyon', label: 'Twitter' },
  { label: 'T', href: 'https://www.twitch.tv/collectorscanyon', isText: true }
];

const FarcasterSection = ({ themeClasses }) => (
  <>
    <h2 className="text-5xl font-bold mb-8 text-center text-orange-500">Join Our Web3 Community</h2>
    <div className={`${themeClasses.contentBg} border-2 ${themeClasses.border} rounded-lg p-8 shadow-2xl backdrop-blur-sm max-w-4xl mx-auto`}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="space-y-4 hover:transform hover:scale-105 transition-all duration-300">
          <div className="h-40 bg-gradient-to-br from-purple-600 to-orange-500 rounded-lg flex items-center justify-center">
            <h3 className="text-2xl font-bold text-white">Farcaster Channel</h3>
          </div>
          <a 
            href="https://warpcast.com/~/channel/collectorscanyon"
            target="_blank"
            rel="noopener noreferrer"
            className="block p-4 rounded-lg bg-black/50 hover:bg-black/70 transition-all"
          >
            <p className="text-lg text-center">Join the conversation in our dedicated Farcaster channel</p>
          </a>
        </div>

        <div className="space-y-4 hover:transform hover:scale-105 transition-all duration-300">
          <div className="h-40 bg-gradient-to-br from-orange-500 to-yellow-500 rounded-lg flex items-center justify-center">
            <h3 className="text-2xl font-bold text-white">Explorer Pass</h3>
          </div>
          <a 
            href="https://www.hypersub.xyz/s/tier-1-explorer-pass-sbjo6bwja58g/1"
            target="_blank"
            rel="noopener noreferrer"
            className="block p-4 rounded-lg bg-black/50 hover:bg-black/70 transition-all"
          >
            <p className="text-lg text-center">Get exclusive access with our Explorer Pass</p>
          </a>
        </div>

        <div className="space-y-4 hover:transform hover:scale-105 transition-all duration-300">
          <div className="h-40 bg-gradient-to-br from-yellow-500 to-purple-600 rounded-lg flex items-center justify-center">
            <h3 className="text-2xl font-bold text-white">Courtyard Profile</h3>
          </div>
          <a 
            href="https://courtyard.io/user/collectorscanyon"
            target="_blank"
            rel="noopener noreferrer"
            className="block p-4 rounded-lg bg-black/50 hover:bg-black/70 transition-all"
          >
            <p className="text-lg text-center">View our collection on Courtyard.io</p>
          </a>
        </div>
      </div>
    </div>
  </>
);
const AboutContent = ({ themeClasses }) => (
    <div className={`${themeClasses.contentBg} border-2 ${themeClasses.border} rounded-lg p-8 shadow-2xl backdrop-blur-sm max-w-4xl mx-auto`}>
      <h2 className="text-4xl font-bold mb-8 text-orange-500">About Us</h2>
      <h3 className="text-3xl font-semibold mb-6">Our History</h3>
      
      <p className="text-xl mb-6">
        Welcome to Collectors Canyon, the ultimate destination for collectors worldwide.
      </p>
      
      <div className="space-y-6 text-lg leading-relaxed">
        <p>
          Collectors Canyon, established in 2023, is a dynamic, ever-evolving marketplace 
          and community connecting collectors of all stripes. We strive to bring together 
          those who are passionate about collectibles, be it vintage cars, antiques, coins, 
          stamps, or comic books, under one digital roof.
        </p>
        
        <p>
          Founded by a team of dedicated enthusiasts, Collectors Canyon is built on a shared 
          love for preserving, sharing, and discovering unique items that tell compelling stories. 
          Our mission is to create a platform that not only facilitates buying, selling, and 
          trading collectibles but also fosters a vibrant community of collectors where knowledge, 
          experiences, and passions are shared.
        </p>
        
        <p>
          Our platform features a diverse array of collectibles, from the rarest finds to 
          the most sought-after pieces. Our team ensures the authenticity and quality of every 
          item, providing our members with a secure, trustworthy platform to expand their collection.
        </p>
        
        <p>
          Beyond being a marketplace, Collectors Canyon is a hub of information, offering resources, 
          articles, forums, and events that serve to educate and entertain our community. Our 
          dedicated blog features expert insights, trend analyses, collector profiles, and more, 
          keeping our users engaged and informed about the world of collectibles.
        </p>
        
        <p>
          At Collectors Canyon, we understand the value of a collection extends beyond its 
          monetary worth – it's about the history, the chase, the community, and the joy of 
          discovery. We invite you to join us on this exciting journey, whether you're a 
          seasoned collector or just starting out.
        </p>
        
        <p className="text-xl font-semibold text-orange-500 mt-8">
          Welcome to Collectors Canyon – your journey to collecting begins here!
        </p>
      </div>
    </div>
  );
  
  const GradingContent = ({ themeClasses }) => (
    <>
      <h2 className="text-5xl font-bold mb-8 text-center text-orange-500">Grading Services</h2>
      <div className={`${themeClasses.contentBg} border-2 ${themeClasses.border} rounded-lg p-8 shadow-2xl backdrop-blur-sm max-w-4xl mx-auto`}>
        <img src="/gradedcards.jpg" alt="Graded Cards" className="w-full h-64 object-cover rounded-lg mb-8" />
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="space-y-4">
            <h3 className="text-2xl font-bold text-orange-500">Value, Quality</h3>
            <p className="text-lg leading-relaxed">
              At Collectors Canyon, we recognize the importance of grading in preserving the value 
              and allure of your collectibles. Our professional grading services are designed to 
              help you determine the quality and condition of your treasured items, ensuring they 
              retain their worth and charm for generations to come.
            </p>
          </div>
  
          <div className="space-y-4">
            <h3 className="text-2xl font-bold text-orange-500">Guidance, Clarity</h3>
            <p className="text-lg leading-relaxed">
              Understanding the grading process can be challenging, especially for newcomers. 
              That's why we strive to make our services accessible and easy to understand. 
              We'll guide you through the grading process, explaining each aspect so you can 
              make informed decisions about your collectibles.
            </p>
          </div>
  
          <div className="space-y-4">
            <h3 className="text-2xl font-bold text-orange-500">Partnership, Preservation</h3>
            <p className="text-lg leading-relaxed">
              With Collectors Canyon, grading is not just a service; it's a partnership. 
              We work with you to preserve the value of your items and enhance their appeal 
              to potential buyers or fellow collectors. Trust in our expertise, and let us 
              help you uncover the true potential of your collectibles.
            </p>
          </div>
        </div>
      </div>
    </>
  );
  const SellContent = ({ themeClasses }) => (
    <>
      <h2 className="text-5xl font-bold mb-8 text-center text-orange-500">Sell Your Collection</h2>
      <div className={`${themeClasses.contentBg} border-2 ${themeClasses.border} rounded-lg p-8 shadow-2xl backdrop-blur-sm max-w-4xl mx-auto`}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-4">
            <img src="/videogames.png" alt="Video Games" className="w-full h-48 object-cover rounded-lg" />
            <h3 className="text-2xl font-bold text-orange-500">Video Games and Systems</h3>
            <p className="text-lg leading-relaxed">
              From the earliest consoles to the latest next-gen devices, we are interested in your video games 
              and systems sell us Today. Whether it's Atari, NES, PS 5, or anything in between, we are always 
              on the hunt for both the mainstream and the obscure. Games of all genres and for all platforms 
              pique our interest. We are especially interested in complete-in-box or sealed items, but we also 
              welcome individual cartridges, discs, and gaming accessories.
            </p>
          </div>
  
          <div className="space-y-4">
            <img src="/tradingcards.png" alt="Trading Cards" className="w-full h-48 object-cover rounded-lg" />
            <h3 className="text-2xl font-bold text-orange-500">Trading Cards</h3>
            <p className="text-lg leading-relaxed">
              Trading cards bridge the gap between fans and their passions. We're keen to buy cards from all 
              areas - sports, gaming, pop culture, and more. Baseball, football, basketball cards, or Magic: 
              The Gathering, Pokémon, Yu-Gi-Oh, we're interested in them all. From vintage rookie cards to 
              the latest holographic pulls, single cards, complete sets, or sealed booster boxes - we'd love 
              to see what you have.
            </p>
          </div>
  
          <div className="space-y-4">
            <img src="/comics.png" alt="Comics" className="w-full h-48 object-cover rounded-lg" />
            <h3 className="text-2xl font-bold text-orange-500">Comics</h3>
            <p className="text-lg leading-relaxed">
              Comics are where stories come to life, and we adore them all. From milestone issues to indie 
              titles, golden age to modern age, Marvel to DC and beyond - we're fascinated by comics of every 
              sort. Single issues, complete runs, graphic novels, and even original comic art - if it's related 
              to comics, we want to know about it. Graded comics or those with a significant provenance are of 
              particular interest, but we're happy to take a look at any comic you have.
            </p>
          </div>
  
          <div className="space-y-4">
            <img src="/funko.png" alt="Funko Figures" className="w-full h-48 object-cover rounded-lg" />
            <h3 className="text-2xl font-bold text-orange-500">Funko Figures</h3>
            <p className="text-lg leading-relaxed">
              Funko has taken the collectible world by storm with their extensive line of adorable vinyl figures. 
              Pop!, Dorbz, Mystery Minis, Vinyl Soda, and more - we're thrilled to add them to our collection 
              sell us today. Whether you have common figures or hard-to-find exclusives, single figures or a 
              massive collection, we'd be excited to check it out.
            </p>
          </div>
  
          <div className="space-y-4">
            <img src="/coins.png" alt="Coins" className="w-full h-48 object-cover rounded-lg" />
            <h3 className="text-2xl font-bold text-orange-500">Coins</h3>
            <p className="text-lg leading-relaxed">
              Coins are historical treasures that transcend time, and we appreciate their story. Ancient coins, 
              commemorative coins, mint sets, proofs, domestic or international - we're interested in all coinage. 
              Gold, silver, copper, or other materials - all coins have value, both monetary and historical.
            </p>
          </div>
  
          <div className="space-y-4">
            <img src="/artwork.png" alt="Rare Artwork" className="w-full h-48 object-cover rounded-lg" />
            <h3 className="text-2xl font-bold text-orange-500">Rare Artwork</h3>
            <p className="text-lg leading-relaxed">
              Art is the epitome of human creativity, and we hold it in high regard. Prints, lithographs, 
              original paintings, sculptures, or other forms of artistic expression - we are interested in it 
              all sell us today. Contemporary artists, old masters, or undiscovered talents - if it's a unique 
              piece of art, we'd love to consider it. We appreciate signed pieces, numbered editions, and 
              original works with significant provenance.
            </p>
          </div>
        </div>
      </div>
    </>
  );
  
  const Footer = ({ themeClasses }) => {
    const [isHoursOpen, setIsHoursOpen] = useState(false);
    
    const hours = {
      'Monday': '09:00 am – 05:00 pm',
      'Tuesday': '09:00 am – 05:00 pm',
      'Wednesday': '09:00 am – 05:00 pm',
      'Thursday': '09:00 am – 05:00 pm',
      'Friday': '09:00 am – 05:00 pm',
      'Saturday': 'Closed',
      'Sunday': 'Closed'
    };
  
    return (
      <footer className={`${themeClasses.footer} p-8 border-t`}>
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">Contact Us</h3>
            <p>Collectors Canyon</p>
            <p className="text-orange-500">602-935-8363</p>
            
            <div className="mt-4">
              <button 
                onClick={() => setIsHoursOpen(!isHoursOpen)}
                className="flex items-center gap-2 hover:text-orange-500"
              >
                <span>Hours</span>
                <span className="text-sm">{isHoursOpen ? '▼' : '▶'}</span>
              </button>
              {isHoursOpen && (
                <div className="mt-2 bg-gray-900 p-4 rounded">
                  {Object.entries(hours).map(([day, time]) => (
                    <div key={day} className="flex justify-between text-sm">
                      <span>{day}</span>
                      <span>{time}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
  
          <div>
            <h3 className="text-xl font-bold mb-4">Connect With Us</h3>
            <div className="flex gap-4 flex-wrap">
              {socialLinks.map((link) => (
                <SocialLink key={link.label} {...link} />
              ))}
            </div>
          </div>
  
          <div className="text-center md:text-right">
            <p>© 2024 Collectors Canyon Card Grading.</p>
            <p>All rights reserved.</p>
          </div>
        </div>
      </footer>
    );
  };
  
  const Website = () => {
    const [isDarkMode, setIsDarkMode] = useState(true);
  
    const themeClasses = {
      main: isDarkMode ? 'bg-black text-white' : 'bg-white text-black',
      header: isDarkMode ? 'bg-black' : 'bg-white',
      contentBg: isDarkMode ? 'bg-black/80' : 'bg-white/80',
      border: isDarkMode ? 'border-orange-700' : 'border-orange-300',
      footer: isDarkMode ? 'bg-black border-orange-900' : 'bg-white border-orange-200',
      hover: isDarkMode ? 'hover:text-orange-700' : 'hover:text-orange-500',
      background: isDarkMode ? 'bg-black' : 'bg-white'
    };
  
    const scrollToSection = (id) => {
      const element = document.getElementById(id);
      const headerOffset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    };
  
    return (
      <div className={`min-h-screen ${themeClasses.main}`} style={{ fontFamily: 'Rajdhani' }}>
        <header className={`fixed w-full ${themeClasses.header} p-4 shadow-lg z-50`}>
          <div className="container mx-auto flex items-center justify-between">
            <div className="flex items-center gap-4">
              <img src="/perry.png" alt="Perry Logo" className="w-[100px] h-[100px]" />
              <h1 className="text-2xl font-bold">Collectors Canyon Card Grading</h1>
            </div>
            
            <nav className="flex items-center gap-6">
              <button onClick={() => scrollToSection('welcome')} 
                      className={`transition-colors ${themeClasses.hover}`}>
                Home
              </button>
              <button onClick={() => scrollToSection('about')} 
                      className={`transition-colors ${themeClasses.hover}`}>
                About
              </button>
              <button onClick={() => scrollToSection('grading')}
                      className={`transition-colors ${themeClasses.hover}`}>
                Grading
              </button>
              <button onClick={() => scrollToSection('sell')}
                      className={`transition-colors ${themeClasses.hover}`}>
                Sell
              </button>
              <button onClick={() => scrollToSection('farcaster')} 
                      className={`transition-colors ${themeClasses.hover}`}>
                Community
              </button>
              <ThemeToggle isDark={isDarkMode} toggle={() => setIsDarkMode(!isDarkMode)} />
            </nav>
          </div>
        </header>
  
        <main>
          <section id="welcome" className="relative min-h-screen p-8 pt-48 overflow-hidden">
            <DiamondPattern isDark={isDarkMode} />
            <div className="container mx-auto flex items-center justify-center gap-12 relative">
              <img src="/collectorscanyon.png" alt="Collectors Canyon" className="w-1/3 rounded-xl shadow-lg" />
              <div className="text-white">
                <h2 className="text-5xl font-bold mb-4">Welcome to Collectors Canyon Card Grading</h2>
                <p className="text-2xl text-orange-500">702-724-4270</p>
              </div>
            </div>
          </section>
  
          <section id="about" className="relative min-h-screen p-8">
            <DiamondPattern isDark={isDarkMode} />
            <div className="relative">
              <AboutContent themeClasses={themeClasses} />
            </div>
          </section>
  
          <section id="grading" className="relative min-h-screen p-8 pt-32">
            <DiamondPattern isDark={isDarkMode} />
            <div className="relative">
              <GradingContent themeClasses={themeClasses} />
            </div>
          </section>
  
          <section id="sell" className="relative min-h-screen p-8 pt-32">
            <DiamondPattern isDark={isDarkMode} />
            <div className="relative">
              <SellContent themeClasses={themeClasses} />
            </div>
          </section>
  
          <section id="farcaster" className="relative min-h-screen p-8 pt-32">
            <DiamondPattern isDark={isDarkMode} />
            <div className="relative">
              <FarcasterSection themeClasses={themeClasses} />
            </div>
          </section>
        </main>
  
        <Footer themeClasses={themeClasses} />
      </div>
    );
  };
  
  export default Website;